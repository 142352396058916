/* eslint-disable */

// jshint -W117
// noinspection JSUnresolvedFunction,JSUnresolvedVariable, JSPotentiallyInvalidConstructorUsage,JSMismatchedCollectionQueryUpdate
import '../static/major/scss/map.scss';
import 'bootstrap/js/src/modal';

const loadMap = (listener) => {
  // eslint-disable-next-line
  const mapSource = `//maps.google.com/maps/api/js?key=${GMAP_KEY}`;
  const mapScriptID = 'map-script';

  let mapScript;

  if (document.head.innerHTML.indexOf(mapSource) === -1) {
    mapScript = document.createElement('script');
    mapScript.id = mapScriptID;
    mapScript.src = mapSource;
    mapScript.async = true;

    document.head.appendChild(mapScript);
  }

  mapScript = document.getElementById(mapScriptID);
  mapScript.addEventListener('load', listener);
};

async function buildMap(mapData, storeName, storeId) {
  const point = new google.maps.LatLng(mapData.center.lat, mapData.center.lng);
  const params = {
    center: point,
    zoom: mapData.center.zoom,
    disableDefaultUI: true,
    zoomControl: true,
    zoomControlOptions: {
      position: google.maps.ControlPosition.RIGHT_BOTTOM,
    },
    mapTypeId: google.maps.MapTypeId.ROADMAP,
  };

  const map = new google.maps.Map(
    document.getElementById(mapData.center.target),
    params
  );
  const infoWindow = new google.maps.InfoWindow();
  const bestStoreMarker = new google.maps.Marker({
    position: point,
    map,
    icon: `/media/major/img/map-pin.png`,
  });

  const markers = [bestStoreMarker];

  $('[id^="store-template-"]').each(function () {
    const $this = $(this);
    const marker = new google.maps.Marker({
      position: new google.maps.LatLng(
        $this.data('mapLatitude'),
        $this.data('mapLongitude')
      ),
      title: $this.data('mapTitle'),
      icon: `/media/major/img/map-pin${
        $this.data('mapCount') ? '' : '-gray'
      }.png`,
      shadow: '/media/major/img/map-pin-shadow.png',
      map,
    });

    marker.addListener('click', function () {
      const content = $this.val();
      infoWindow.setContent(content);
      infoWindow.open(map, this);
    });

    $(`.map-address[data-store=${$this.data('store')}]`).data('marker', marker);
    markers.push(marker);
  });

  const mapPosition = $(`#${mapData.center.target}`).offset();

  // Best store
  const bestStoreControl = document.createElement('span');
  TopRithControl(bestStoreControl); // jshint ignore:line
  map.controls[google.maps.ControlPosition.TOP_RIGHT].push(bestStoreControl);

  // We wait for the map to be fully loaded before triggering a marker click and
  // showing its corresponding infoWindow, or else the infoWindow won't be correctly
  // centered on the map.
  //
  // We also don't open an infoWindow directly because we might attach extra
  // functionality to the 'click' event later on.
  google.maps.event.addListenerOnce(map, 'idle', function () {
    const marker = $(`.map-address[data-store=${storeId}]`).data('marker');
    new google.maps.event.trigger(marker, 'click');
  });

  $('.map-address').on('click', function (e) {
    const target = $(e.target);
    // avoid redefining btn (or btn content) click
    if (target.hasClass('btn') || target.parent().hasClass('btn')) {
      return;
    }

    e.preventDefault();

    infoWindow.close();
    window.scrollTo(mapPosition.left, mapPosition.top);

    const lat = $(this).data('lat');
    const lng = $(this).data('lng');
    const marker = $(this).data('marker');

    // Wait for the map to load.
    google.maps.event.addListenerOnce(map, 'tilesloaded', () => {
      new google.maps.event.trigger(marker, 'click');
    });

    map.setCenter(new google.maps.LatLng(lat, lng));
  });

  function TopRithControl(controlElement) {
    // jshint ignore:line
    controlElement.innerHTML = `<b>Best Store for You:</b> ${storeName}`;
    $(controlElement).css({
      margin: '5px',
      padding: '1px 6px',
      border: 'solid 1px #717B87',
      background: '#fff',
    });

    // Setup the click event listeners: simply set the map to Chicago.
    controlElement.addEventListener('click', function () {
      // map.hideInfoWindows();
      window.scrollTo(mapPosition.left, mapPosition.top);

      const marker = $(`.map-address[data-store=${storeId}]`).data('marker');
      new google.maps.event.trigger(marker, 'click');
    });
  }
}

window.initGMap = (target, lat, lng, storeName, storeId) => {
  loadMap(() => {
    const mapData = {
      center: {
        target,
        lat,
        lng,
        zoom: 15,
      },
    };
    buildMap(mapData, storeName, storeId);
  });
};

window.initStoreMap = (target, lat, lng) => {
  loadMap(() => {
    const point = new google.maps.LatLng(lat, lng);
    const params = {
      center: point,
      zoom: 13,
      disableDefaultUI: true,
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM,
      },
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    };

    const map = new google.maps.Map(document.getElementById(target), params);
    new google.maps.Marker({ position: point, map });
  });
};

window.initStoreMapAsync = (target, lat, lng) => {
  const point = new google.maps.LatLng(lat, lng);
  const params = {
    center: point,
    zoom: 13,
    disableDefaultUI: true,
    zoomControl: true,
    zoomControlOptions: {
      position: google.maps.ControlPosition.RIGHT_BOTTOM,
    },
    mapTypeId: google.maps.MapTypeId.ROADMAP,
  };

  const map = new google.maps.Map(document.getElementById(target), params);
  new google.maps.Marker({ position: point, map });
};

window.initStoresNearbyMap = (mapData) => {
  loadMap(() => {
    const point = new google.maps.LatLng(
      mapData.stores[0].lat,
      mapData.stores[0].lng
    );
    const params = {
      center: point,
      zoom: mapData.center.zoom,
      disableDefaultUI: true,
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM,
      },
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    };
    const map = new google.maps.Map(
      document.querySelector(mapData.center.div),
      params
    );
    const infoWindow = new google.maps.InfoWindow();

    for (let i = 0; i < mapData.stores.length; i++) {
      const store = mapData.stores[i];
      const point = new google.maps.LatLng(store.lat, store.lng);

      new google.maps.Marker({
        position: point,
        title: store.title,
        icon: '/media/major/img/map-pin.png',
        shadow: '/media/major/img/map-pin-shadow.png',
        map,
      }).addListener('click', function () {
        infoWindow.setContent(`${store.title}<br/>${store.desc}`);
        infoWindow.open(map, this);
      });
    }
  });
};

window.initStoreLocationMap = (target, lat, lng) => {
  window.initStoreMap(target, lat, lng);
};

window.initZipMap = (mapData) => {
  window.initBrandMap(mapData, 'zip');
};

window.initBrandMap = (mapData, mapType) => {
  loadMap(() => {
    mapData.icons = {
      deal: {
        url: '/media/images/marker_deal.png',
        shadow: '/media/images/marker_shadow.png',
        size: new google.maps.Size(22, 35),
        shadowSize: new google.maps.Size(40, 33),
        anchor: new google.maps.Point(11, 35),
        infoWindowAnchor: new google.maps.Point(19, 3),
      },
      featured: {
        url: '/media/images/marker_featured.png',
        shadow: '/media/images/marker_shadow.png',
        size: new google.maps.Size(22, 35),
        shadowSize: new google.maps.Size(40, 33),
        anchor: new google.maps.Point(11, 35),
        infoWindowAnchor: new google.maps.Point(19, 3),
      },
      featured_deal: {
        // jshint ignore:line
        url: '/media/images/marker_featured_deal.png',
        shadow: '/media/images/marker_shadow.png',
        size: new google.maps.Size(22, 35),
        shadowSize: new google.maps.Size(40, 33),
        anchor: new google.maps.Point(11, 35),
        infoWindowAnchor: new google.maps.Point(19, 3),
      },
      rated: {
        url: '/media/images/marker_rated.png',
        shadow: '/media/images/marker_shadow.png',
        size: new google.maps.Size(16, 26),
        shadowSize: new google.maps.Size(28, 24),
        anchor: new google.maps.Point(8, 26),
        infoWindowAnchor: new google.maps.Point(14, 5),
      },
      dot: {
        url: '/media/images/marker_dot.png',
        shadow: '/media/images/marker_dot_shadow.png',
        size: new google.maps.Size(20, 20),
        shadowSize: new google.maps.Size(20, 20),
        anchor: new google.maps.Point(10, 10),
        infoWindowAnchor: new google.maps.Point(10, 10),
      },
      ldp: {
        url: '/media/images/star_location.png',
        shadow: '/media/images/marker_shadow.png',
        size: new google.maps.Size(20, 30),
        shadowSize: new google.maps.Size(40, 33),
        anchor: new google.maps.Point(11, 35),
        infoWindowAnchor: new google.maps.Point(19, 3),
      },
    };

    if (mapType === 'zip') {
      /* featured with deal icon */
      mapData.icons.deal = {
        url: '/media/images/marker_deal.png',
        size: new google.maps.Size(22, 35),
        shadow: {
          url: '/media/images/marker_shadow.png',
          size: new google.maps.Size(40, 33),
          anchor: new google.maps.Point(9, 0),
        },
        infoWindowAnchor: new google.maps.Point(19, 3),
      };

      /* featured icon */
      mapData.icons.featured = mapData.icons.deal;
      mapData.icons.featured.url = '/media/images/marker_featured.png';

      // jshint ignore:start
      mapData.icons.featured_deal = mapData.icons.deal;
      mapData.icons.featured_deal.url =
        '/media/images/marker_featured_deal.png';
      // jshint ignore:end

      /* rated icon */
      mapData.icons.rated = {
        url: '/media/images/marker_rated.png',
        size: new google.maps.Size(16, 26),
        anchor: new google.maps.Point(0, 8),
        shadow: {
          url: '/media/images/marker_shadow.png',
          scaledSize: new google.maps.Size(28, 24),
          anchor: new google.maps.Point(-1, 8),
        },
        infoWindowAnchor: new google.maps.Point(14, 5),
      };

      /* dot icon */
      mapData.icons.dot = {
        url: '/media/images/marker_dot.png',
        size: new google.maps.Size(20, 20),
        anchor: new google.maps.Point(10, 10),
        shadow: {
          url: '/media/images/marker_dot_shadow.png',
          size: new google.maps.Size(20, 20),
          anchor: new google.maps.Point(10, 10),
        },
        infoWindowAnchor: new google.maps.Point(10, 10),
      };
    }

    const target = document.getElementById('googleMap');
    const config = {
      center: new google.maps.LatLng(
        mapData.center ? mapData.center.latitude : 41,
        mapData.center ? mapData.center.longitude : -95.9
      ),
      zoom: mapData.zoom ? mapData.zoom : mapData.center ? 12 : 4,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    };

    mapData.map = new google.maps.Map(target, config);
    if (mapData.center) {
      makeMarkers();
    }
  });

  function makeMarkers() {
    // jshint ignore:line
    const infoWindow = new google.maps.InfoWindow();
    const locationBounds = new google.maps.LatLngBounds();
    const fitMapToLocations = mapData.fitToLocations === 'True';

    for (const key in mapData.locations) {
      const loc = mapData.locations[key];
      const point = new google.maps.LatLng(loc.latitude, loc.longitude);

      loc.marker = new google.maps.Marker({
        position: point,
        map: mapData.map,
        title: loc.name,
        loc,
        icon: mapData.icons[loc.type],
      });

      if (fitMapToLocations) {
        locationBounds.extend(point);
      }

      loc.marker.addListener('click', function () {
        // jshint ignore:line
        // Create Address Info Tab
        const phone =
          this.loc.phone !== '' ? `<i>${this.loc.phone}</i><br/>` : '';

        const strTab1HTML = `
        <div style="font:12px arial">
          <strong><a href="${this.loc.permalink}">${this.title}</a></strong><br/>
          ${this.loc.displayAddress}<br/>
          ${phone}
          <a href="https://maps.google.com/maps?q=${this.loc.googleAddress}"
            target="_blank">View on Google Maps</a><br/>
        </div>`;

        infoWindow.setContent(strTab1HTML);
        infoWindow.open(mapData.map, this);
      });
    }

    if (fitMapToLocations) {
      mapData.map.fitBounds(locationBounds);
    }
  }

  window.showOnMap = (id) => {
    window.scrollTo(0, 215);

    const { marker } = mapData.locations[id];

    google.maps.event.trigger(marker, 'click');

    const markerPosition = marker.getPosition();
    mapData.map.panTo(markerPosition);
  };

  window.toggleStores = (id) => {
    jQuery(`#${id}_more`).toggle();
    const a = jQuery(`#${id}_link`);
    if (a.text() === '. More .') {
      a.text('^ Less ^');
    } else {
      a.text('. More .');
    }
  };

  window.mapInput = (input) => {
    // for the inputs with default initial string, like "type zip here"
    input.value = '';
    input.style.color = '#333';
  };
};
